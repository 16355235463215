
import { defineComponent } from "vue";
import AppRouterLink from "@/components/AppRouterLink.vue";

export default defineComponent({
  name: "PageNotFound",
  components: { AppRouterLink },
  props: {},
  emits: [],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {},
});
